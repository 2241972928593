
import React, {useState, useEffect} from "react" 
import { Helmet } from "react-helmet";       
import axiosInstance from "../client";
import Layout from "../components/layout/Layout"; 
import { Loading } from "../components/layout/Loading"; 
import { InformationCircleIcon } from '@heroicons/react/solid'
 
const DeskaPage = ({ location}) => {

    const [deska, setDeska] = useState();
    const [loading, setLoading] = useState(false);   
 
    useEffect(() => {
        setLoading(true); 
        async function getData() {
            try {
                const res = await axiosInstance.get('?e=tpl&tpl=deska');
                setDeska(res.data)
                setLoading(false); 
            // Do your JSON handling here
            } catch(err) {
                console.log(err);
                // This probably means your response is text, do you text handling here
            }
        }
        getData()
         
    }, []);
 
  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Úřední deska | Exekutorský úřad Praha 9</title> 
        </Helmet>  

        <Layout location={location} pageContext={{title:"Úřední deska"}} > 

        {loading ? <Loading loading={loading} /> 
          
          : 
            <div className="mx-auto w-full  relative  ">
                <div  className="mx-auto max-w-6xl w-full px-8 pb-10 lg:px-14   z-auto  relative">
                    <div className="rounded-md bg-blue-50 p-4 my-3">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">Zobrazeny jsou pouze nejnovější dokumenty na úřední desce</p>
                            <p className="mt-3 text-sm md:mt-0 md:ml-6">
                                <a href="http://g2crz9v.infoekcr.cz/" target="_blank"  rel="noreferrer" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                                Kompletní výpis <span aria-hidden="true">&rarr;</span>
                                </a>
                            </p>
                            </div>
                        </div>
                    </div>
               {deska && <div className="prose max-w-6xl " dangerouslySetInnerHTML={{__html: deska}} >
                                
                </div>
                }
                </div>
            </div>
        }
        </Layout>
    </>
  )
}
 
export default DeskaPage
 